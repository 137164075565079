import React from 'react'
import { Map, Quote } from '../components'

const Contact = () => {
  return (
    <div>
      <Quote />
      <Map />
    </div>
  )
}

export default Contact