// BelowSection.js
import React, { useEffect } from "react";
// import img7 from '.ass/img7.png'
import Aos from "aos";
import "aos/dist/aos.css";

const Below = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <div className=" lg:py-[2.5%] box-border pt-[5%] pb-[10%]  gap-[4%] px-[15%] grid  lg:grid-cols-2 lg:px-[8%] bg-gray-100">
      <div data-aos="zoom-out-up" className="flex justify-center items-center">
        <img className="w-[100%] " src="./assets/about.png" alt="" />
      </div>
      <div className="lg:pr-[10%] xl:py-[4%] pb-[2%] flex flex-col justify-between font-urbanist text-justify ">

        <div className="  xl:text-xl ">
        <h1 data-aos="zoom-out-up" className="pb-4 text-lg lg:text-xl ">
          Established in Singapore by seasoned marine engineers, ATHENA Martech
          Pte. Ltd. specialises in delivering round-the-clock support for
          diverse ship services.
        </h1>
          <p data-aos="zoom-out-up">
            Our company is dedicated to providing customers with dependable,
            cost-effective, top-notch, timely engine, deck, and electrical
            services, both dockside and during voyages, without disrupting
            vessel schedules.
          </p>
          <p data-aos="zoom-out-up" className="py-[2%] xl:py-[6%]">
            At ATHENA, our team consists of a riding crew comprising qualified
            engineers and technicians equipped with the technical know-how to
            handle a broad spectrum of repair services. We prioritise
            operational excellence, aiming to minimise asset downtime and
            deliver highly efficient engine services.
          </p>
          <p data-aos="zoom-out-up" className="pb-5">
            Leveraging our expertise, skilled workforce, and ample resources,
            ATHENA is committed to offering solutions for all planned or
            unplanned repairs and maintenance to be executed safely.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Below;
