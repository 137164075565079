import React from 'react'
import { CTA, Swipe } from '../components'
import { StickyScrollRevealDemo } from '../components/Product/StickyScrollRevealDemo'
import { LayoutGridDemo } from '../components/Product/LayoutGridDemo'

const Product = () => {
  return (
    <div>
      <Swipe />
      <StickyScrollRevealDemo />
      <LayoutGridDemo />
      <CTA />
    </div>
  )
}

export default Product