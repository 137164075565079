import React from 'react'
import { CTA, Core, Hero, Objective, Repair, Services } from '../components'

const Home = () => {
  return (
    <div>
      <Hero />
      <Objective />
      <Repair />
      <Services />
      <Core />
      <CTA />
    </div>
  )
}

export default Home