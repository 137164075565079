import React, { useState } from "react";
import FadeUpDown from "../../animation/FadeUpDown";
import { BsFillTelephoneFill } from "react-icons/bs";
import { IoIosMail } from "react-icons/io";
import { FaLocationDot } from "react-icons/fa6";


const Quote = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { name, email, phone, subject, message } = user;

    if (name && email && phone && subject && message) {
      const res = await fetch(
        "https://athena-martech-new-default-rtdb.firebaseio.com/form-responses.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            subject,
            message,
          }),
        }
      );

      //   axios.post('https://sheet.best/api/sheets/2073497f-0f2c-4e63-a8c8-99f23420814d',user);

      if (res) {
        setUser({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });

        alert("Message Sent!");
      }
    } else {
      alert("Please fill all the fields!");
    }
  };

  return (
    <div>
      <div
        style={{
          backgroundImage: `url('./assets/quote.png')`,
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <h1 className="font-archivo grid md:text-6xl lg:text-7xl text-3xl text-white uppercase font-semibold lg:p-[10%] p-[20%] md:p-[15%]">
          speak to us <span className="lg:pl-[5%] pt-[3%] ">on your needs</span>
        </h1>
      </div>
      <div className="grid lg:grid-cols-2">
        <div className="md:px-[20%] md:py-20 py-10 px-10">
          <div className="pb-16">
            <h3 className="text-primary uppercase font-archivo text-lg font-medium">
              WE WOULD BE GLAD TO HELP!
            </h3>
            <h1 className="font-archivo text-5xl">Send us an Enquiry</h1>
          </div>
          <div>
            <h3 className="text-grey font-medium pb-10"><span className="text-primary font-semibold">ATHENA Martech Pte. Ltd.</span> - Your partner for dependable, cost-effective ship services, ensuring top-notch support round-the-clock.</h3>
          <div className="flex items-center gap-4 pb-10">
              <FaLocationDot className="h-5 w-5 text-primary" />
              <div className="text-grey font-medium">
                <h3 className="w-60">1, Bukit Batok Crescent, #05-32, WCEGA Plaza, Singapore 658064</h3>
              </div>
            </div>
          </div>
          <div className="grid md:grid-cols-2">
            <div className="flex items-center gap-4">
              <BsFillTelephoneFill className="h-5 w-5 text-primary" />
              <div className="text-grey font-medium">
                <a href="tel:+65 69708124" className="hover:text-primary cursor-pointer">
                  <h3>+65 69708124</h3>
                  </a>
                <a href="tel:+65 69708125" className="hover:text-primary cursor-pointer">
                  <h3>+65 69708125</h3>
                  </a>
                <a href="tel: +65 97708083" className="hover:text-primary cursor-pointer">
                  <h3>+65 97708083</h3>
                  </a>
              </div>
            </div>
            <div className="flex items-center gap-4 md:pt-0 pt-5">
              <div>
                <IoIosMail className="w-7 h-7 text-primary" />
              </div>
              <div className="text-grey font-medium">
                <a href="mailto:info@athena.com.sg" className="hover:text-primary cursor-pointer">
                  <h3>Repair Services: info@athena.com.sg</h3>
                  </a>
                <a href="mailto:sales@athena.com.sg" className="hover:text-primary cursor-pointer">
                  <h3>Safety Services: sales@athena.com.sg</h3>
                  </a>
              </div>
            </div>
          </div>
        </div>
        <div>
          <div className="md:px-[20%] md:py-20 py-10 px-10">
            <form method="POST">
              <FadeUpDown xOffset={15} delay={0.4}>
                <div class="grid gap-6">
                  <div class="relative z-0 w-full group">
                    <input
                      type="text"
                      name="name"
                      id="name"
                      class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.name}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="name"
                      class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      Full Name
                    </label>
                  </div>
                  <div class="relative z-0 mb-6 w-full group">
                    <input
                      type="email"
                      name="email"
                      id="email"
                      class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.email}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="email"
                      class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      Email
                    </label>
                  </div>
                </div>
                <div class="grid gap-6">
                  <div class="relative z-0 w-full group">
                    <input
                      type="tel"
                      pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                      name="phone"
                      id="phone"
                      class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.phone}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="phone"
                      class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      Phone Number
                    </label>
                  </div>
                  <div class="relative z-0 mb-6 w-full group">
                    <input
                      type="text"
                      name="subject"
                      id="subject"
                      class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                      placeholder=" "
                      value={user.subject}
                      onChange={getUserData}
                      autoComplete="off"
                      required
                    />
                    <label
                      for="subject"
                      class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                    >
                      Subject
                    </label>
                  </div>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <textarea
                    rows={3}
                    type="text"
                    name="message"
                    id="message"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.message}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="message"
                    class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Message
                  </label>
                </div>
                <div className="pt-5 grid">
                  <button
                    onClick={postData}
                    type="submit"
                    className="text-lg font-gill text-white shadow-xl bg-primary px-7 py-1 rounded-lg font-medium border-2 border-primary hover:text-primary hover:bg-white"
                  >
                    Submit
                  </button>
                </div>
              </FadeUpDown>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Quote;
