import React from "react";
// import img3 from "./assets/img3.png";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";

import { Swiper, SwiperSlide } from "swiper/react";

import { Autoplay } from "swiper/modules";

const Core = () => {
  useEffect(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const List = [
    {
      id: 1,
      num: "01",
      title: "Safety & Environment",
      des: "Environmental preservation is a top priority for our team, and we emphasise the significance of maintaining a secure and healthy workplace. Continuously, we evaluate and improve our operations to proactively mitigate risks.",
      img: "./assets/c1.png",
    },
    {
      id: 2,
      num: "02",
      title: "Performance",
      des: "We are committed to enhancing our company's performance guided by exceptionally high standards and strong values. We actively foster an environment that encourages continuous improvement, innovation, and creativity.",
      img: "./assets/c3.png",
    },
    {
      id: 3,
      num: "03",
      title: "Engagement",
      des: "Our employees are deeply engaged in their roles, taking full ownership of their work. At ATHENA Martech, we actively seek and embrace personal responsibility for our actions and results. We take pride in delivering exceptional customer service.",
      img: "./assets/c2.png",
    },
    {
      id: 4,
      num: "04",
      title: "Integrity",
      des: "Integrity lies at the core of our identity and actions. We are dedicated to consistently treating both customers and company resources with the respect they rightfully deserve.",
      img: "./assets/c4.png",
    },
    {
      id: 5,
      num: "05",
      title: "Honesty",
      des: "Our commitment extends to maintaining honesty and fairness in our dealings with customers, employees, stakeholders, and among ourselves. We pledge to uphold truthfulness, trustworthiness, and honour in every facet of our work.",
      img: "./assets/c5.png",
    },
    {
      id: 6,
      num: "06",
      title: "Environment & Pollution",
      des: "We maintain strict vigil and comply with international standards for environment & pollution control.",
      img: "./assets/c6.png",
    },
    {
      id: 7,
      num: "07",
      title: "Responsibility",
      des: "We actively embrace and assume personal responsibility for both our actions and outcomes. We bear the responsibility of integrating quality into every aspect of our work. Delivering exceptional customer service is a source of pride for us.",
      img: "./assets/c7.png",
    },
  ];
  return (
    <div className="md:grid relative h-screen bg-gray-100  grid-cols-2 ">
      <div className="sm:pl-[20%] py-[10%]   pl-[10%] md:pt-[10%] md:py-0 flex flex-col">
        <div data-aos="fade-right" className="pb-[28%]   ">
          <p className="text-[#298BC1] font-semibold">CORE VALUES</p>
          <h1 className="2xl:text-7xl text-3xl lg:text-5xl sm:text-4xl font-archivo font-medium w-[70%]">
            What We Prioritise at ATHENA
          </h1>
        </div>
        <div
          data-aos="fade-right"
          className="flex flex-col text-black  items-start font-urbanist text-lg"
        >
          <p className=" hover:text-primary">
            Safety & Environment
          </p>
          <p className=" hover:text-primary">Performance</p>
          <p className="hover:text-primary">Engagement</p>
          <p className=" hover:text-primary">Integrity</p>
          <p className=" hover:text-primary">Honesty</p>
          <p className=" hover:text-primary">
            Environment & Pollution
          </p>
          <p className=" hover:text-primary">Responsibility</p>
        </div>
      </div>
      <div>
        <Swiper
          spaceBetween={0}
          centeredSlides={true}
          loop={true}
          autoplay={{
            delay: 2500,
            pauseOnMouseEnter: true,
          }}
          modules={[Autoplay]}
          className="mySwiper h-full "
        >
          {List.map((Items) => (
            <SwiperSlide className="h-full  ">
              <div className="bg-[#298BC1] h-full pb-[18%] pt-[10%] px-[10%] sm:pl-[17%]">
                <div className="grid grid-cols-5">
                  <div className="col-span-2 flex items-center">
                    <h1
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="5000"
                      className="text-white text-3xl font-extralight"
                    >
                      {Items.num}
                    </h1>
                  </div>
                  <div className="flex col-span-3 justify-center">
                    <img
                      data-aos="flip-left"
                      data-aos-easing="ease-out-cubic"
                      data-aos-duration="5000"
                      src={Items.img}
                      className="w-[80%]"
                      alt=""
                    />
                  </div>
                </div>
                <div className="text-white font-light flex flex-col pt-[5%] gap-8 sm:w-[65%]  ">
                  <h1
                    data-aos="zoom-out-right"
                    className="text-3xl font-archivo "
                  >
                    {Items.title}
                  </h1>
                  <p className=" font-urbanist" data-aos="zoom-out-right">{Items.des}</p>
                </div>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
    
  );
};

export default Core;