import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./style.css";

// import required modules
import { Parallax, Autoplay, Navigation  } from "swiper/modules";

const Swipe = () => {
  const List = [
    {
      id: 1,
      title: "Products & Services",
      para: "Experienced marine engineers delivering round-the-clock ship services, minimizing downtime.",
    },
    {
      id: 2,
      title: "Products & Services",
      para: "Reliable, cost-effective, and timely engine, deck, and electrical services for vessels.",
    },
    {
      id: 3,
      title: "Products & Services",
      para: "Qualified riding crew ensuring safe and efficient repairs and maintenance solutions.",
    },
  ];
  return (
    <div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        speed={600}
       
        
        parallax={true}
        modules={[Parallax, Autoplay ]}
        autoplay={{
            // delay: 4500,
            disableOnInteraction: false,
            pauseOnMouseEnter:true,
          }}
          breakpoints={{
              0: {
                autoplay: {
                  delay: 5000,
                }
              },
              640: {
                autoplay: {
                  delay: 2500,
                }
               
              },

             
            }}
        className="mySwiper relative"
      >
        <div
          slot="container-start"
          className="parallax-bg h-screen"
          style={{
            backgroundImage: `url('./assets/product.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          data-swiper-parallax="-8%"
        ></div>

        {List.map((Items) => (
          <SwiperSlide>
            <div className="grid bottom-7 right-10 absolute text-right sm:text-left sm:static">
              <div
                className="subtitle sm:absolute sm:bottom-[8vw] text-3xl sm:text-5xl text-white font-semibold sm:left-[8vw] right-[8vw] xs:bottom-[30vw] bottom-[40vw]"
                data-swiper-parallax="-200"
              >
                {Items.title}
              </div>
              <div
                className="text sm:absolute right-[8vw] bottom-[5vw] md:text-xl lg:text-2xl font-semibold text-white  sm:text-right pl-10 pt-8 sm:w-[25%] xl:w-[35%]"
                data-swiper-parallax="-100"
              >
                <p>{Items.para}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Swipe;
