"use client";
import React from "react";
import { LayoutGrid } from "../ui/layout-grid";

export function LayoutGridDemo() {
  return (
    <div className="bg-white py-20 w-full">
      <h1 className="text-center text-4xl pb-4 font-archivo font-semibold text-primary">
        Auxillary Engine
      </h1>
      <LayoutGrid cards={Auxillary} />
      <LayoutGrid cards={Auxillary2} />
      <h1 className="text-center text-4xl py-4 font-archivo font-semibold text-primary">
        Main Engine
      </h1>
      <LayoutGrid cards={Main} />
      <h1 className="text-center text-4xl py-4 font-archivo font-semibold text-primary">
      Steel Repairs & Fabrication
      </h1>
      <LayoutGrid cards={Steel1} />
      <LayoutGrid cards={Steel2} />
      <h1 className="text-center text-4xl py-4 font-archivo font-semibold text-primary">
        Safety Products
      </h1>
      <LayoutGrid cards={Safety} />
    </div>
  );
}

const SkeletonOne = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Auxillary Engine</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Our expert technicians specialize in complete overhauls for auxiliary
        engines, ensuring peak performance for a range of brands including Man,
        Wartsila, Himsen, Yanmar, Daihatsu, Caterpillar, Cummins, and Mitsubishi
      </p>
    </div>
  );
};

const SkeletonTwo = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Auxillary Engine</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        From fuel pump repair to crankshaft and camshaft inspection, we provide
        comprehensive auxiliary engine maintenance, troubleshooting, and renewal
        services, keeping your vessels operating efficiently and reliably.
      </p>
    </div>
  );
};
const SkeletonThree = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Main Engine</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Trust our team for comprehensive main engine overhauls, including
        meticulous bearing inspection and renewal for main, thrust, camshaft,
        and crosshead components, ensuring optimal performance and longevity.
      </p>
    </div>
  );
};
const SkeletonFour = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Main Engine</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        With expertise in component renewal and alternator repairs, alongside
        general maintenance and troubleshooting, we deliver top-tier main engine
        services tailored to your vessel's specific needs, guaranteeing smooth
        operations at sea.
      </p>
    </div>
  );
};
const SkeletonFive = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Safety Products</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
        Ensure maritime safety with our range of high-quality safety products
        designed to protect crew and vessels alike, providing peace of mind in
        every voyage.
      </p>
    </div>
  );
};

const SkeletonSix = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Steel repairs & Fabrication</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
      We offer major steel renewals for deck plates, shell plates, and internal tanks, along with minor repairs such as hatch coamings and socket renewals, ensuring your vessel's structural integrity.
      </p>
    </div>
  );
};

const SkeletonSeven = () => {
  return (
    <div>
      <p className="font-bold text-4xl text-white">Steel repairs & Fabrication</p>
      <p className="font-normal text-base text-white"></p>
      <p className="font-normal text-base my-4 max-w-lg text-neutral-200">
      Specializing in low-temperature steel repairs, our experienced riding squads perform repairs flexibly in workshops, ports, anchorages, or during voyages.
      </p>
    </div>
  );
};

const Auxillary = [
  {
    id: 1,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux1.jpg",
  },
  {
    id: 3,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux3.jpg",
  },
  {
    id: 4,
    content: <SkeletonTwo />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux4.jpg",
  },
  {
    id: 5,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux5.jpg",
  },
  {
    id: 6,
    content: <SkeletonTwo />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux6.jpg",
  },
  {
    id: 7,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux7.jpg",
  },
  {
    id: 8,
    content: <SkeletonTwo />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux8.jpg",
  },
  {
    id: 9,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux9.jpg",
  },
  {
    id: 10,
    content: <SkeletonTwo />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux10.jpg",
  },
];

const Auxillary2 = [
  {
    id: 11,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux11.jpg",
  },
  {
    id: 12,
    content: <SkeletonTwo />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux12.jpg",
  },
  {
    id: 13,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux13.jpg",
  },
  {
    id: 14,
    content: <SkeletonTwo />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux14.jpg",
  },
  {
    id: 15,
    content: <SkeletonOne />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux15.jpg",
  },
  {
    id: 16,
    content: <SkeletonTwo />,
    className: "md:col-span-1",
    thumbnail: "../assets/aux16.jpg",
  },
];

const Main = [
  {
    id: 1,
    content: <SkeletonThree />,
    className: "md:col-span-1",
    thumbnail: "../assets/main1.jpg",
  },
  {
    id: 2,
    content: <SkeletonFour />,
    className: "col-span-1",
    thumbnail: "../assets/main2.jpg",
  },
  {
    id: 3,
    content: <SkeletonThree />,
    className: "md:col-span-1",
    thumbnail: "../assets/main3.jpg",
  },
  {
    id: 4,
    content: <SkeletonFour />,
    className: "col-span-1",
    thumbnail: "../assets/main4.jpg",
  },
  {
    id: 5,
    content: <SkeletonThree />,
    className: "md:col-span-1",
    thumbnail: "../assets/main5.jpg",
  },
  {
    id: 6,
    content: <SkeletonFour />,
    className: "col-span-1",
    thumbnail: "../assets/main6.jpg",
  },
  {
    id: 7,
    content: <SkeletonThree />,
    className: "md:col-span-1",
    thumbnail: "../assets/main7.jpg",
  },
  {
    id: 8,
    content: <SkeletonFour />,
    className: "col-span-1",
    thumbnail: "../assets/main8.jpg",
  },
];
const Safety = [
  {
    id: 1,
    content: <SkeletonFive />,
    className: "md:col-span-1",
    thumbnail: "../assets/safe1.jpg",
  },
  {
    id: 2,
    content: <SkeletonFive />,
    className: "md:col-span-1",
    thumbnail: "../assets/safe2.jpg",
  },
  {
    id: 3,
    content: <SkeletonFive />,
    className: "md:col-span-1",
    thumbnail: "../assets/safe3.jpg",
  },
  {
    id: 4,
    content: <SkeletonFive />,
    className: "md:col-span-1",
    thumbnail: "../assets/safe4.jpg",
  },
  {
    id: 5,
    content: <SkeletonFive />,
    className: "md:col-span-1",
    thumbnail: "../assets/safe5.jpg",
  },
];


const Steel1 = [
  {
    id: 1,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st1.jpg",
  },
  {
    id: 2,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st2.jpg",
  },
  {
    id: 3,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st3.jpg",
  },
  {
    id: 4,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st4.jpg",
  },
  {
    id: 5,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st5.jpg",
  },
  {
    id: 6,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st6.jpg",
  },
  {
    id: 7,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st7.jpg",
  },
  {
    id: 8,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st8.jpg",
  },
  {
    id: 9,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st9.jpg",
  },
];

const Steel2 = [
  {
    id: 10,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st10.jpg",
  },
  {
    id: 11,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st11.jpg",
  },
  {
    id: 12,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st12.jpg",
  },
  {
    id: 13,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st13.jpg",
  },
  {
    id: 14,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st14.jpg",
  },
  {
    id: 15,
    content: <SkeletonSeven />,
    className: "md:col-span-1",
    thumbnail: "../assets/st15.jpg",
  },
  {
    id: 16,
    content: <SkeletonSix />,
    className: "md:col-span-1",
    thumbnail: "../assets/st16.jpg",
  }
];
