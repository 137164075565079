import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Navbar from './Layout/Navbar';
// import { PreLoader } from './components';
import Home from './pages/Home';
import About from './pages/About';
import Contact from './pages/Contact';
import Footer from './Layout/Footer';
import Product from './pages/Product';

function App() {
  return (
    <div className='overflow-hidden'>
      {/* <PreLoader /> */}
      {/* <Navbar />
      <Home /> */}

      <BrowserRouter>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/products" element={<Product />} />
          <Route path="/about" element={<About />} />
          <Route path='/contact' element={<Contact />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
