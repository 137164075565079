import React from 'react'
import { Below, CTA, Cardani, Cert, Core, Paraswipe } from '../components'

const About = () => {
  return (
    <div>
      <Paraswipe />
      <Below />
      <Cardani />
      <Cert />
      <Core />
      <CTA />
    </div>
  )
}

export default About