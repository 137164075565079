import React from "react";
import { Link } from "react-router-dom";

const Repair = () => {

    const scrollToTop = () => {
        window.scrollTo(0, 0);
      };

      
  return (
    <div className="grid lg:grid-cols-2 gap-20 px-[10%] sm:px-[20%] lg:px-[10%] pb-[5%]">
      <div
        data-aos="fade-right"
        className="lg:flex justify-center items-center rounded-xl"
      >
        <img className="rounded-xl" src="../assets/img11.png" alt="" />
      </div>
      <div
        data-aos="fade-left"
        className="flex flex-col gap-4 xl:gap-8 justify-center"
      >
        <h1 className="xl:text-4xl w-[80%] text-xl sm:text-2xl font-archivo">
          
          <span className="shadow-lg under ">Ship Repair & Maintenance services</span>{" "}
          
        </h1>
        <p className="py[4%] lg:w-[80%] font-urbanist font-medium">
          Based in Singapore, ATHENA Martech Pte. Ltd., spearheaded by a team of
          seasoned marine engineers, offers specialised 24x7 support for a wide
          array of ship repair and maintenance services. Our dedicated team,
          comprising qualified engineers and expert technicians, delivers a
          comprehensive range of repair services.
        </p>
        <div>
          <Link to="/about" onClick={scrollToTop}>
            <button className="bg-primary border-2 border-primary hover:bg-white hover:text-primary font-urbanist font-semibold text-white py-3 px-6 rounded-lg">
              Read More
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default Repair;
