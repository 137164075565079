import React, { useState } from "react";
// import { IoCall } from "react-icons/io5";
// import { GoMail } from "react-icons/go";
// import { FaLocationDot } from "react-icons/fa6";
import FadeUpDown from "../../animation/FadeUpDown";
// import axios from "axios";

const CTA = () => {
  const [user, setUser] = useState({
    name: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  let name, value;
  const getUserData = (event) => {
    name = event.target.name;
    value = event.target.value;

    setUser({ ...user, [name]: value });
  };

  const postData = async (e) => {
    e.preventDefault();

    const { name, email, phone, subject, message } = user;

    if (name && email && phone && subject && message) {
      const res = await fetch(
        "https://athena-martech-new-default-rtdb.firebaseio.com/form-responses.json",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            name,
            email,
            phone,
            subject,
            message,
          }),
        }
      );

      //   axios.post('https://sheet.best/api/sheets/2073497f-0f2c-4e63-a8c8-99f23420814d',user);

      if (res) {
        setUser({
          name: "",
          email: "",
          phone: "",
          subject: "",
          message: "",
        });

        alert("Message Sent!");
      }
    } else {
      alert("Please fill all the fields!");
    }
  };

  return (
    <section className="lg:h-screen" id="contact-us">
      <div className="grid lg:grid-cols-2 h-full">
        <div className="h-full">
          {/* map */}
          <div className="">
            <FadeUpDown xOffset={15} delay={0.6}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7294334865855!2d103.75655687447205!3d1.33863546161283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da115ecef7a62b%3A0xb1a589989a7fde08!2sWCEGA%20Plaza!5e0!3m2!1sen!2sin!4v1713005161271!5m2!1sen!2sin"
                className="w-full lg:h-screen h-[100vh]"
                height="300"
                title="map"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </FadeUpDown>
          </div>
        </div>
        <div className="md:px-[20%] md:py-20 py-10 px-10">
            <div className="pb-16">
                <h3 className="text-primary uppercase font-archivo text-lg font-medium">CONTACT US</h3>
                <h1 className="font-archivo text-5xl">Get a Quote Now</h1>
            </div>
          <form method="POST" className="lg:mx-auto mx-[20%]">
            <FadeUpDown xOffset={15} delay={0.4}>
              <div class="grid gap-6">
                <div class="relative z-0  w-full group">
                  <input
                    type="text"
                    name="name"
                    id="name"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.name}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="name"
                    class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Full Name
                  </label>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="email"
                    name="email"
                    id="email"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.email}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="email"
                    class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Email
                  </label>
                </div>
              </div>
              <div class="grid gap-6">
                <div class="relative z-0 w-full group">
                  <input
                    type="tel"
                    pattern="[0-9]{3}-[0-9]{3}-[0-9]{4}"
                    name="phone"
                    id="phone"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.phone}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="phone"
                    class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Phone Number
                  </label>
                </div>
                <div class="relative z-0 mb-6 w-full group">
                  <input
                    type="text"
                    name="subject"
                    id="subject"
                    class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                    placeholder=" "
                    value={user.subject}
                    onChange={getUserData}
                    autoComplete="off"
                    required
                  />
                  <label
                    for="subject"
                    class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                  >
                    Subject
                  </label>
                </div>
              </div>
              <div class="relative z-0 mb-6 w-full group">
                <textarea
                  rows={3}
                  type="text"
                  name="message"
                  id="message"
                  class="block py-2.5 px-0 w-full text-sm text-primary bg-white/[0.1] rounded border-0 border-2 border-gray appearance-none focus:outline-none focus:ring-0 focus:border-primary peer font-semibold font-gill"
                  placeholder=" "
                  value={user.message}
                  onChange={getUserData}
                  autoComplete="off"
                  required
                />
                <label
                  for="message"
                  class="peer-focus:font-semibold font-semibold left-3 absolute text-sm peer-focus:text-primary duration-300 transform -translate-y-6 scale-75 top-3 peer-focus:top-0 z-10 origin-[0] peer-focus:left-0 peer-focus:text-primary peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-6 font-gill"
                >
                  Message
                </label>
              </div>
              <div className="pt-5 grid">
                <button
                  onClick={postData}
                  type="submit"
                  className="text-lg font-gill text-white shadow-xl bg-primary px-7 py-1 rounded-lg font-medium border-2 border-primary hover:text-primary hover:bg-white"
                >
                  Submit
                </button>
              </div>
            </FadeUpDown>
          </form>
        </div>
      </div>
    </section>
  );
};

export default CTA;
