import React, { useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { FaBars } from "react-icons/fa";
import { ImCross } from "react-icons/im";

const Navbar = () => {
  const list = [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About Us",
      link: "/about",
    },
    {
      text: "Product & Services",
      link: "/products",
    },
    {
      text: "Contact Us",
      link: "/contact",
    },
  ];

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  const [nav, setNav] = useState(false);

  const handleNav = () => {
    setNav(!nav);
  };

  return (
    <section>
      <div className="px-[12%] text-sm w-full py-8 fixed z-50 top-0 drop-shadow-xl nav font-urbanist font-semibold ">
        <div className="lg:pl-10 bg-white rounded-full py-2 lg:grid flex lg:grid-cols-4 justify-around md:px-0 px-6">
          <div className="col-span-1 flex justify-center items-center">
            <NavLink to="/" onClick={scrollToTop}>
              <img className="w-[80%] md:w-full" src="./assets/logo..png" alt="" />
            </NavLink>
          </div>
          <div className="lg:flex col-span-3 px-[5%]  justify-between items-center hidden">
            {list.map((lists) => (
              <NavLink
                to={lists.link}
                onClick={scrollToTop}
                className="navlink text-lg hover:text-primary"
              >
                {lists.text}
              </NavLink>
            ))}
            <NavLink to="/contact" onClick={scrollToTop}>
              <button className="bg-primary text-white rounded-full px-6 py-1 border-2 border-primary hover:bg-white hover:text-primary">
                Get Quote
              </button>
            </NavLink>
          </div>
          <div className="lg:hidden flex items-center" onClick={handleNav}>
            {!nav ? (
              <FaBars className="text-red" />
            ) : (
              <ImCross className="text-red" />
            )}
          </div>
        </div>
      </div>


      <div className={nav ? "bg-white/[0.9] h-screen fixed z-30 w-[70%] ease-in-out duration-1000 pt-[20%] px-5 text-center lg:hidden" : "hidden"}>
        {list.map((link) => (
          <div className="py-7 border-b-[1px] border-primary" onClick={handleNav}>
            <NavLink to={link.link} onClick={scrollToTop}>
              <button className="text-xl font-urbanist font-extrabold hover:text-primary">{link.text}</button>
            </NavLink>
          </div>
        ))}
        <div className="py-7" onClick={handleNav}>
          <Link to="/contact" onClick={scrollToTop}>
            <button className="text-white font-urbanist w-full text-xl bg-primary px-6 py-2 rounded-xl border-2 border-primary hover:bg-white hover:text-primary font-extrabold">
              Get Quote
            </button>
          </Link>
        </div>
      </div>
    </section>
  );
};

export default Navbar;
