import React from 'react'
import Aos from 'aos'
import 'aos/dist/aos.css'
import { useEffect } from "react";

const Objective = () => {
  useEffect(()=>{
    Aos.init({duration: 2000});
   },[]);
  return (
    <div data-aos="flip-left"
    data-aos-easing="ease-out-cubic"
    data-aos-duration="8000" className='md:px-[20%]  font-urbanist lg:leading-relaxed sm:leading-normal px-[10%] text-2xl sm:text-3xl py-[10%]'>
    <p className='text-center p-2'>Embark on a voyage of reliability with Athena Martech. Our commitment ensures <span className='text-primary font-semibold'>top-tier Engine, Deck, and Electrical maintenance, guaranteeing prompt and quality solutions</span> that never compromise your ship's schedule. Sail confidently with us!</p>
      
    </div>
  )
}

export default Objective