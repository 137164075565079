import React, { useEffect } from "react";
import gsap from "gsap";

const Hero = () => {
  useEffect(() => {
    gsap.set(".nav", { y: -100 });
    gsap.set(".letter-wrapper", { y: 400 });
    gsap.set(".item-copy-wrapper", { y: 50 });

    gsap.defaults({ duration: 1, ease: "power3.out" });
    const tl = gsap.timeline({ pause: true, delay: 0.5 });

    //animation 1: letters coming up
    tl.to(".letter-wrapper", {
      y: 0,
      stagger: 0.1,
    })
      .to(".header-item-1", {
        left: "12vw",
        color: "white",
      })
      .to(
        ".header-item-2",
        {
          right: "8vw",
          color: "white",
        },
        "<"
      )
      .to(
        ".item-main .item-video video",
        {
          clipPath: "polygon(0% 100%, 100% 100%, 100% 0%, 0% 0%)",
        },
        "<"
      )
      .to(".header-item-1", {
        left: 0,
        scale: 1,
        color: "white"
      })
      .to(
        ".header-item-2",
        {
          right: 0,
          scale: 1,
          color: "white"
        },
        "<"
      )
      .to(
        ".item-main .item-video video",
        {
          scale: 1,
        },
        "<"
      )
      .to(
        ".header",
        {
          bottom: (window.innerWidth < 737 ? "10%" : "-1em"),
          left: "1em",
          color: "white"
        },
        "<"
      )
      .to(".nav", {
        y: 0,
      });
  }, []);

  return (
    <div className="container lg:h-screen w-screen">
      <div className="items">
        <div className="item item-main relative">
          {/* <div className="item-copy">
              <div className="item-copy-wrapper">
                <p>Lorem ipsum</p>
              </div>
            </div> */}
          <div className="item-video w-[100vw]">
            <video autoPlay muted className="h-screen w-screen">
              <source
                src="https://res.cloudinary.com/dmon8ij1m/video/upload/v1714078643/Untitled_design_skgsyr.mp4"
                type="video/mp4"
              />
            </video>
          </div>
        </div>
      </div>

      <div className="header font-syne text-primary font-extrabold">
        <div className="header-item header-item-1">
          <div className="letter">
            <div className="letter-wrapper">A</div>
          </div>
          <div className="letter">
            <div className="letter-wrapper">T</div>
          </div>
          <div className="letter">
            <div className="letter-wrapper">H</div>
          </div>
        </div>
        <div className="header-item header-item-2">
          <div className="letter">
            <div className="letter-wrapper">E</div>
          </div>
          <div className="letter">
            <div className="letter-wrapper">N</div>
          </div>
          <div className="letter">
            <div className="letter-wrapper">A</div>
          </div>
        </div>
                
      </div>
    </div>
  );
};

export default Hero;
