import React, { useEffect } from 'react'
// import'../../App.css'
// import img8 from './img8.png'
// import img9 from './img9.png'
import Aos from 'aos'
import 'aos/dist/aos.css'

const Cardani = () => {
   useEffect(()=>{
    Aos.init({duration: 2000});
   },[]);
   
  return (
    <div className='  px-[10%] grid gap-[5%]  sm:gap-[10%] sm:pb-[10%] md:pb-[15%] pb-[20%] pt-[10%]'>
        <div data-aos="zoom-in" className='bg-[#298BC1]   rounded-xl sm:grid grid-cols-2'>
            
            <div   className='text-white p-[5%] md:p-[10%]'>
                <h1 className='lg:text-7xl text-3xl md:text-5xl pb-[10%] lg:pb-[20%] font-archivo'>Vision</h1>
                <p className='xl:text-2xl text-md md:text-lg xl:pr-[10%] text-justify font-urbanist'>ATHENA aims to emerge as the foremost customer support provider and a leading force in the marine power propulsion sector. Our commitment revolves around achieving operational excellence, prioritising safety and reducing asset downtime through the delivery of exceptionally efficient engine services.</p>
            </div>
            <div  className=' flex justify-center items-center p-[8%] sm:p-[5%]'>
                <img className='' src='./assets/img8.png' alt=''/>
            </div>
        </div>
        <div data-aos="zoom-in" className='bg-[#A1B8C5]   rounded-xl sm:grid grid-cols-2'>
            
            <div   className='text-white p-[5%] md:p-[10%]'>
                <h1 className='lg:text-7xl text-3xl md:text-5xl pb-[10%] lg:pb-[20%] font-archivo'>Mission</h1>
                <p className='xl:text-2xl text-md md:text-lg xl:pr-[10%] text-justify font-urbanist'>ATHENA is committed to providing superior technical solutions and attentive service, emphasising safety, cost-effectiveness, adherence to schedules, and upholding our core values. Our team consists of dedicated professionals who wholeheartedly collaborate to contribute to the realisation of our vision.</p>
            </div>
            <div  className=' flex justify-center items-center p-[8%] sm:p-[5%]'>
                <img className='' src='./assets/img9.png' alt=''/>
            </div>
        </div>
        

    </div>
  )
}

export default Cardani