import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles

import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

// import "./style.css";

// import required modules
import { Parallax, Autoplay } from "swiper/modules";

const Paraswipe = () => {
  const List = [
    {
      id: 1,
      title: "ABOUT US",
      para1:
        "Experienced marine engineers delivering round-the-clock ship services, minimizing downtime.",
      para2:
        "Reliable, cost-effective, and timely engine, deck, and electrical services for vessels.",
      para3:
        "Qualified riding crew ensuring safe and efficient repairs and maintenance solutions.",

      para: "Experienced marine engineers delivering round-the-clock ship services, minimizing downtime.",
    },
    {
      id: 2,
      title: "ABOUT US",
      para1:
        "Experienced marine engineers delivering round-the-clock ship services, minimizing downtime.",
      para2:
        "Reliable, cost-effective, and timely engine, deck, and electrical services for vessels.",
      para3:
        "Qualified riding crew ensuring safe and efficient repairs and maintenance solutions.",
      para: "Reliable, cost-effective, and timely engine, deck, and electrical services for vessels.",
    },
    {
      id: 3,
      title: "ABOUT US",
      para1:
        "Experienced marine engineers delivering round-the-clock ship services, minimizing downtime.",
      para2:
        "Reliable, cost-effective, and timely engine, deck, and electrical services for vessels.",
      para3:
        "Qualified riding crew ensuring safe and efficient repairs and maintenance solutions.",
      para: "Qualified riding crew ensuring safe and efficient repairs and maintenance solutions.",
    },
  ];
  return (
    <div>
      <Swiper
        style={{
          "--swiper-navigation-color": "#fff",
          "--swiper-pagination-color": "#fff",
        }}
        
        speed={600}
        parallax={true}
        modules={[Parallax, Autoplay]}
        autoplay={{
          // delay: 500,
          disableOnInteraction: false,
          pauseOnMouseEnter: true,
        }}
        breakpoints={{
              0: {
                autoplay: {
                  delay: 5000,
                }
              },
              640: {
                autoplay: {
                  delay: 2500,
                }
               
              },

             
            }}
        
        className="mySwiper relative"
      >
        <div
          slot="container-start"
          className="parallax-bg h-screen"
          style={{
            backgroundImage: `url('./assets/bg2.png')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
          data-swiper-parallax="-8%"
        ></div>
        <div className="absolute md:grid hidden px-[10%] grid-cols-3 pb-[5%] gap-12 bottom-0 left-0 text-white">
        <div className="col-span-3 pb-6"><p className="flex text-3xl  sm:text-5xl text-white font-semibold">About Us</p></div>
          {
            List.map((Items)=>(
              <div className=" md:text-xl lg:text-2xl font-semibold text-white gap-4 md:text-left  ">
                <p>{Items.para}</p>
              </div>
            ))
          }
        </div>

        {List.map((Items) => (
          <SwiperSlide>
            {/* for desktop version  */}
            {/* <div className="md:grid hidden bottom right-10 absolute left-0 bottom-10 text-right sm:text-left  grid-cols-4 mx-auto w-[90%] xl:w-[80%] ">
              <div
                className="subtitle  flex items-center  text-3xl sm:text-5xl text-white font-semibold "
                data-swiper-parallax="-200"
              >
                {Items.title}
              </div>
              <div
                className="text col-span-3   md:text-xl lg:text-2xl grid grid-cols-3  font-semibold text-white gap-4 md:text-left  pl-10 pt-8 xl:w[20%]"
                data-swiper-parallax="-100"
              >
                <p className="">{Items.para1}</p>
                <p>{Items.para2}</p>
                <p>{Items.para3}</p>
              </div>
            </div> */}

            {/* for mobile version */}

            <div className="grid md:hidden bottom-7 right-10 absolute text-right sm:text-left sm:static">
              <div
                className="subtitle sm:absolute sm:bottom-[8vw] text-3xl sm:text-5xl text-white font-semibold sm:left-[8vw] right-[8vw] xs:bottom-[30vw] bottom-[40vw]"
                data-swiper-parallax="-200"
              >
                {Items.title}
              </div>
              <div
                className="text sm:absolute right-[8vw] bottom-[5vw] md:text-xl lg:text-2xl font-semibold text-white  sm:text-right pl-10 pt-8 sm:w-[25%] xl:w-[20%]"
                data-swiper-parallax="-100"
              >
                <p>{Items.para}</p>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default Paraswipe;
