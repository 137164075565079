const breakpoints = [1080, 640, 384, 256, 128, 96, 64, 48];

const pictures = [
    {
        src: "https://res.cloudinary.com/dmon8ij1m/image/upload/v1712962665/cert1_sl3dts.jpg",
        height: "400",
        width: "250"
    },
    {
        src: "https://res.cloudinary.com/dmon8ij1m/image/upload/v1712962665/cert2_f5yj9o.jpg",
        height: "400",
        width: "250"
    },
    {
        src: "https://res.cloudinary.com/dmon8ij1m/image/upload/v1712962665/cert3_twahtd.jpg",
        height: "400",
        width: "250"
    },
    {
        src: "./assets/biz.png",
        height: "400",
        width: "250"
    },
];

const photos = pictures.map((photo) => ({
    src: photo.src,
    width: photo.width,
    height: photo.height,
    srcSet: breakpoints.map((breakpoint) => {
        const height = Math.round((photo.height / photo.width) * breakpoint);
        return {
            src: photo.src,
            width: breakpoint,
            height,
        };
    }),
}));


export default photos;