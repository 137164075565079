import React from "react";
import { IoMdPin } from "react-icons/io";
import { IoCall } from "react-icons/io5";
import { Link } from "react-router-dom";
import { IoMailOpenSharp } from "react-icons/io5";
import { Divider } from "@mui/material";

const Footer = ({ items }) => {
  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <section className="bg-primary/[0.4] text-black md:pt-5 pt-2">
      <div className="md:grid grid-cols-2">
        <div></div>
        <div className="flex gap-4 lg:gap-[12%] flex-wrap sm:flex-nowrap justify-evenly md:justify-start text-lg justify-cente ">
            {items.map((item) => (
              <Link to={item.link} smooth onClick={scrollToTop}>
                <li className="list-none relative group py-1 font-urbanist hover:text-primary font-semibold cursor-pointer">
                  {item.text}
                  <span className="lg:absolute hidden bottom-1 left-0 w-0 h-0.5 bg-secondary transition-all group-hover:w-[20%]"></span>
                </li>
              </Link>
            ))}
          </div>
      </div>
      <Divider className="pt-4" />
      <div className="grid lg:grid-cols-2 mt-10 lg:px-[%] px-5 pb-5">
        <div className=" flex pb-10 md:pb-0  h-full justify-center items-center">
          <Link to="#" smooth onClick={scrollToTop}>
            <img
              className=" md:w-[40vw] cursor-pointer"
              src="./assets/logo..png"
              alt="logo"
            />
          </Link>
          {/* <p className=" my-5 leading-normal mx-auto font-medium text-lg font-urbanist text-justify">
          Based in Singapore, ATHENA Martech Pte. Ltd., spearheaded by a team of seasoned marine engineers, offers specialised 24x7 support for a wide array of ship repair and maintenance services.
          </p> */}
        </div>
        <div>
          <div className="grid md:grid-cols-2 gap-4 text-center md:text-justify pt-8">
            <div className="flex flex-col gap-2">
              <h1 className="text-lg font-semibold font-archivo">Locate Us</h1>
              <p className="font-urbanist font-semibold">Address</p>
              <p className="md:w-[50%] font-urbanist font-semibold">
                1, Bukit Batok Crescent, #05-32, WCEGA Plaza, Singapore 650864
              </p>
            </div>
            <div className="flex flex-col gap-2">
              <h1 className="text-lg font-semibold font-archivo">
                Contact Us
              </h1>
              <h1 className="font-urbanist font-semibold text-center md:text-justify flex gap-3 text-lg items-center">
               <IoCall />
               Phone
             </h1>
              <div>

             <p className=" font-urbanist font-semibold">
               <a href="tel:+65 69708124" className="hover:text-primary">
                 +65 69708124
               </a>
             </p>
             <p className="font-urbanist font-semibold">
               <a href="tel:+65 69708125" className="hover:text-primary">
                 +65 69708125
               </a>
             </p>
             <p className="font-urbanist  font-semibold">
               <a href="tel:+65 97708083" className="hover:text-primary">
                 +65 97708083
               </a>
             </p>


             <h1 className="font-urbanist pt-5 pb-2 font-semibold flex gap-3 text-lg items-center">
               <IoMailOpenSharp />
               Email
             </h1>
             <p className="font-urbanist  font-semibold">
               <a href="mailto:info@athena.com.sg" className="hover:text-primary">
                 Repair Services: info@athena.com.sg
               </a>
             </p>
             <p className="font-urbanist  font-semibold">
               <a href="mailto:sales@athena.com.sg" className="hover:text-primary">
                 Safety Services: sales@athena.com.sg
               </a>
             </p>
              </div>
            </div>
          </div>
        </div>

       
      </div>
<Divider />
    
      <div className="flex justify-between text-xs px-[5%] font-semibold font-archivo py-2">
        <p>Copyrights © All Rights Reserved - ATHENA Martech Pte. Ltd.</p>
        {/* <p>Website Designed and Developed by </p> */}
      </div>
    </section>
  );
};

Footer.defaultProps = {
  items: [
    {
      text: "Home",
      link: "/",
    },
    {
      text: "About Us",
      link: "/about",
    },
    {
      text: "Products",
      link: "/products",
    },
    {
      text: "Contact Us",
      link: "/contact",
    },
  ],
};

export default Footer;
