"use client";

import React, { useRef } from "react";
import { useMotionValueEvent, useScroll } from "framer-motion";
import { motion } from "framer-motion";
import { cn } from "../../utils/cn";

export const StickyScroll = ({ content, content2, contentClassName }) => {
  const [activeCard, setActiveCard] = React.useState(0);
  const ref = useRef(null);
  const { scrollYProgress } = useScroll({
    // uncomment line 22 and comment line 23 if you DONT want the overflow container and want to have it change on the entire page scroll
    // target: ref,
    container: ref,
    offset: ["start start", "end start"],
  });

  const cardLength = content.length;

  useMotionValueEvent(scrollYProgress, "change", (latest) => {
    const cardsBreakpoints = content.map((_, index) => index / cardLength);
    const closestBreakpointIndex = cardsBreakpoints.reduce(
      (acc, breakpoint, index) => {
        const distance = Math.abs(latest - breakpoint);
        if (distance < Math.abs(latest - cardsBreakpoints[acc])) {
          return index;
        }
        return acc;
      },
      0
    );
    setActiveCard(closestBreakpointIndex);
  });

  const backgroundColors = [
    "var(--slate-900)",
    "var(--black)",
    "var(--neutral-900)",
  ];

  const linearGradients = [
    "linear-gradient(to bottom right, var(--cyan-500), var(--emerald-500))",
    "linear-gradient(to bottom right, var(--pink-500), var(--indigo-500))",
    "linear-gradient(to bottom right, var(--orange-500), var(--yellow-500))",
  ];

  return (
    <motion.div
      animate={{
        backgroundColor: backgroundColors[activeCard % backgroundColors.length],
      }}
      className="h-[80rem] lg:h-screen overflow-y-auto block justify-center relative space-x-10 p-10 stickyscroll"
      ref={ref}
    >
      <div>
        <h1 className="text-white font-archivo md:text-5xl text-3xl text-center lg:leading-normal">
          Core Services & Facilities
        </h1>
      </div>
      <div className="div relative lg:grid lg:grid-cols-3 md:flex items-start px-4">
        <div className="">
          {content.map((item, index) => (
            <>
              <div key={item.title + index} className="my-20">
                <motion.h2
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 1,
                  }}
                  className="text-2xl font-semibold text-slate-100 font-archivo"
                >
                  {item.title}
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 1,
                  }}
                  className="text-kg text-slate-300 max-w-lg mt-10 font-urbanist text-justify"
                >
                  {item.description}
                </motion.p>
              </div>
            </>
          ))}
          <div className="h-40" />
        </div>
        {/* <div> */}
        <motion.div
          animate={{
            background: linearGradients[activeCard % linearGradients.length],
          }}
          className={cn(
            "hidden lg:block mx-auto mt-40 h-60 w-80 rounded-md bg-white sticky top-20 overflow-hidden",
            contentClassName
          )}
        >
          {content[activeCard].content ?? null}
        </motion.div>
        {/* </div> */}

        <div className=" lg:block hidden">
          {content2.map((item, index) => (
            <>
              <div key={item.title + index} className="my-20">
                <motion.h2
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 1,
                  }}
                  className="text-2xl font-semibold text-slate-100 font-archivo"
                >
                  {item.title}
                </motion.h2>
                <motion.p
                  initial={{
                    opacity: 0,
                  }}
                  animate={{
                    opacity: activeCard === index ? 1 : 1,
                  }}
                  className="text-kg text-slate-300 max-w-lg mt-10 font-urbanist text-justify"
                >
                  {item.description}
                </motion.p>
              </div>
            </>
          ))}
          <div className="h-40" />
        </div>
      </div>
    </motion.div>
  );
};
