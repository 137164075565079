import React from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";

// import required modules
import { FreeMode, Autoplay } from "swiper/modules";
import { DirectionAwareHover } from "../ui/direction-aware-hover";

const list = [
  {
    imageUrl: "./assets/s1.png",
    text: "Repair & Maintainence"
  },
  // {
  //   imageUrl: "./assets/s2.png",
  //   text: "Reconditioning"
  // },
  {
    imageUrl: "./assets/s3.png",
    text: "Engine Service"
  },
  {
    imageUrl: "./assets/s4.png",
    text: "Fabrication"
  },
  {
    imageUrl: "./assets/s5.png",
    text: "Safety"
  },
  {
    imageUrl: "./assets/s6.png",
    text: "Spare Parts"
  },
]

const Services = () => {
  return (
    <div>
      <div className="px-[10%] pt-10 text-3xl font-semibold font-archivo">Our Services</div>
      <Swiper
        slidesPerView={3.5}
        centeredSlides={true}
        
        grabCursor={true}
        spaceBetween={80}
        breakpoints={{
          0: {
            slidesPerView: 1,
            spaceBetween: 30,
          },
          640: {
            slidesPerView: 1,
            spaceBetween: 30,
          },

          1024: {
            slidesPerView: 2,
            spaceBetween: 30,
          },
          1366: {
            spaceBetween: 80,
          },
        }}
        autoplay={{
          delay: 2500,
          pauseOnMouseEnter: true
        }}
        modules={[FreeMode, Autoplay]}
        className=""
      >
          {
            list.map((lists) => (
              <SwiperSlide>
              <div className="h-[30rem] relative font-archivo flex items-center justify-center">
            <DirectionAwareHover imageUrl={lists.imageUrl}>
              <p className="font-medium text-xl">{lists.text} </p>
              {/* <p></p> */}
            </DirectionAwareHover>
          </div>
        </SwiperSlide>
            ))
          }
      </Swiper>
    </div>
  );
};

export default Services;
