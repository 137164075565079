"use client";
import React from "react";
import { StickyScroll } from "../ui/sticky-scroll-reveal";
// import Image from "next/image";

const content1 = [
  {
    heading: "Core Services & Facilities",
    title: "Overhaul Services for Main and Auxiliary Engines:",
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>Comprehensive Overhauls for Main Engine Units</li>{" "}
          <li>
            Bearing Inspection and Renewal (Main, Thrust, Camshaft, Crosshead)
          </li>{" "}
          <li>
            Complete Overhauls for Auxiliary Engines (MAN, Wartsila, Himsen,
            Yanmar, Daihatsu, Caterpillar, Cummins, Mitsubishi)
          </li>{" "}
          <li>
            Repair and Overhaul of Fuel Pumps for Main and Auxiliary Engines
          </li>{" "}
          <li>Component Renewal for Engines</li>{" "}
          <li>
            Inspection and Renewal of Auxiliary Engine Crankshaft and Camshaft
          </li>{" "}
          <li>General Maintenance, Troubleshooting, and Inspection Services</li>{" "}
          <li>Alternator Repairs</li>{" "}
          <li>Provision of Riding Team with Seaman Book</li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service1.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service1.png" alt="" />
      </div>
    ),
  },

  {
    title: "Steel Fabrication and Repair Services:",
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>
            Major steel renewal for deck plates, shell plates, and internal
            tanks.
          </li>
          <li>
            Minor steel works including hatch coamings, socket renewal, and cell
            guides.
          </li>
          <li>Specialization in repairs involving low-temperature steel.</li>
          <li>
            Flexibility to perform repairs either in workshops, ports,
            anchorages, or during voyages with our experienced riding squads.
          </li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service3.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service3.png" alt="" />
      </div>
    ),
  },

  {
    heading: "Comprehensive Ship Maintenance Services:",
    title: "Main Deck:",
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>Conducting crane load tests</li>
          <li>Renewal of crane jibs</li>
          <li>Repairing gangways</li>
          <li>Overhauling anchor chains</li>
          <li>Repairing winches and windlasses</li>
          <li>Relocating chocks and bollards</li>
          <li>Performing repairs on manholes and funnels</li>
          <li>Reinforcing underdeck steelworks</li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service5.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service5.png" alt="" />
      </div>
    ),
  },

  {
    title: "Engine Room:",
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>Replacing valves</li>
          <li>Cleaning main engine scavenges</li>
          <li>Supplying engine spares</li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service7.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service7.png" alt="" />
      </div>
    ),
  },

  {
    title: "Automation, Refrigeration & Control System Services:",
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>
            Skilled technicians capable of conducting automation troubleshooting
            and services, available in workshops, during port stays, or voyages.
          </li>
          <li>
            Troubleshooting of shipboard hydraulic, pneumatic systems, and
            control systems.
          </li>
          <li>
            Expertise in troubleshooting tank gauging systems, winch/crane
            control systems.
          </li>
          <li>
            Repairs and services for refrigeration and air conditioning system
            controls and automation.
          </li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service9.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service9.png" alt="" />
      </div>
    ),
  },
  {
    title: "Electrical Systems Services:",
    description: (
      <div>
        <p>
          Athena Ship Repairs provides a team of competent electricians with
          technical knowledge to execute various tasks, including:
        </p>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>
            Motor overhauling, rewinding, housing renewal, bearings replacement,
            shaft machining, dynamic balancing, and repair of terminal boxes.
          </li>
          <li>Repairs and inspections of high voltage switchboards.</li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service10.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service10.png" alt="" />
      </div>
    ),
  },
];

const content2 = [
  {
    heading: "",
    title: "Pipe Fabrication and Installation Services:",
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>
            Custom fabrication of pipes according to Owner's drawings, on-site
            measurements, and provided samples from the vessel
          </li>{" "}
          <li>
            Utilisation of Class-approved Welding Procedure Specifications (WPS)
            for materials such as SUS 304, SUS 316L, SMO, and low-temperature
            steel
          </li>{" "}
          <li>
            Specialisation in onboard tasks including modification and repair of
            pipe systems, fabrication, installation, and retrofitting of new
            piping systems, as well as repairs to scrubber overboard piping
          </li>{" "}
          <li>
            Fabrication of specialised components such as S bends and reducers
          </li>{" "}
          <li>
            Extensive experience in handling operations at ports, anchorages,
            and during voyages
          </li>{" "}
          <li>
            Deployment of professional riding squads, readily available for
            mobilisation
          </li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service2.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service2.png" alt="" />
      </div>
    ),
  },
  {
    title: "Workshop Fabrication Services:",
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>
            Athena Ship Repairs has a dedicated storage area and workshop
            equipped with cranes, enhancing accessibility and technological
            capabilities.
          </li>
          <li>
            Our team comprises skilled fitters and welders, overseen by an
            experienced foreman proficient in both steel and piping fabrication.
          </li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service4.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service4.png" alt="" />
      </div>
    ),
  },
  {
    title: <h1 className="lg:pt-16">General:</h1>,
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>Repairing thrusters</li>
          <li>Facilitating vessel name changes</li>
          <li>Cleaning tanks</li>
          <li>Fabricating insulation</li>
          <li>Replacing ladders and gratings</li>
          <li>Fabricating ventilation louvres and heads</li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service6.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service6.png" alt="" />
      </div>
    ),
  },
  {
    title: <h1 className="lg:pt-16">Accomodation:</h1>,
    description: (
      <div>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>Renewing cabin and toilet flooring</li>
          <li>Supplying and replacing bridge windows</li>
          <li>Conducting general painting</li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service8.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service8.png" alt="" />
      </div>
    ),
  },
  {
    title: "Heat Exchanger Services & Spare Supply:",
    description: (
      <div>
        <p>
          Our team of competent technicians possesses the technical expertise to
          carry out a range of tasks related to heat exchangers, including:
        </p>
        <ul className="list-disc pl-5 font-urbanist leading-loose">
          {" "}
          <li>Removal and installation of heat exchangers of all types.</li>
          <li>
            Plate heat exchanger services: Chemical cleaning, UV checking, and
            re-gasketing.
          </li>
          <li>
            Shell and tube heat exchanger services: Chemical cleaning and
            testing.
          </li>
          <li>
            Charge air cooler/radiator type services: Ultrasonic cleaning and
            testing.
          </li>{" "}
        </ul>
        <div className="w-full">
          <img
            className="block lg:hidden mx-auto pt-5"
            src="./assets/service11.png"
            alt=""
          />
        </div>
      </div>
    ),
    content: (
      <div className="h-full w-full">
        <img src="./assets/service11.png" alt="" />
      </div>
    ),
  },
];

export function StickyScrollRevealDemo() {
  return (
    <div className="">
      <StickyScroll content={content1} content2={content2} />
    </div>
  );
}
