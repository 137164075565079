import React from 'react'
import FadeUpDown from "../../animation/FadeUpDown";

const Map = () => {
  return (
    <div>
        <div className="h-full md:p-20 p-10">
          {/* map */}
          <div className='border-4 border-primary rounded-xl'>
            <FadeUpDown xOffset={15} delay={0.6}>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.7294334865855!2d103.75655687447205!3d1.33863546161283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x31da115ecef7a62b%3A0xb1a589989a7fde08!2sWCEGA%20Plaza!5e0!3m2!1sen!2sin!4v1713005161271!5m2!1sen!2sin"
                className="w-full h-[30vw] rounded-xl"
                height="300"
                title="map"
                style={{ border: 0 }}
                allowfullscreen=""
                loading="lazy"
                referrerpolicy="no-referrer-when-downgrade"
              ></iframe>
            </FadeUpDown>
          </div>
        </div>
    </div>
  )
}

export default Map